<template>
    <div class="lk-footer">
        <div class="lk-footer-left">
            <div>
                <!-- <label>{{lg.g_language}}</label>
                <select v-model="lang" @change="lang_update()">
                    <option value="fr">{{lg.g_fr}}</option>
                    <option value="en">{{lg.g_en}}</option>
                    <option value="de">{{lg.g_de}}</option>
                    <option value="nl">{{lg.g_nl}}</option>
                </select> -->
            </div>
        </div>
        <div class="lk-footer-center">
            <div class="lk-footer-center-content">
                <a><img src="@/assets/lokisi2.png" alt="LoKisi"></a>
                <label>&copy; 2024 Accen Informatique - Tous droits réservés</label>
                <label style="display:inline-block;cursor:pointer;" @click="bt_go_mentions">Mentions légales</label>
                <label style="display:inline-block;margin-left:10px;"><a style="font-size:12px;text-decoration: none;" href="./media/cgl_castorama.pdf" target="_blank">Conditions générales de location</a></label>
                <label style="display:inline-block;margin-left:10px;"><a style="font-size:12px;text-decoration: none;" href="./media/charte_rgpd_castorama.pdf" target="_blank">Charte de Protection des Données Personnelles</a></label>
            </div>
        </div>
        <div class="lk-footer-right">
            <!-- <ul>
                <li><i class="fab fa-facebook"></i> Facebook</li>
                <li><i class="fab fa-linkedin"></i> LinkedIn</li>
                <li><i class="fab fa-instagram"></i> Instagram</li>
            </ul> -->
        </div>
    </div>
</template>
 
<script>

import translate from '@/data/translate';

export default {
    data() {
        return {
            lang:''
        }
    },
    props: {

    },
    computed: {
        lg: function () {
            if (localStorage.getItem("lang")===null){
                return translate.get("fr");
            }else{
                return translate.get(localStorage.getItem("lang"));
            }      
        }
    },
    created: function () {
        if (localStorage.getItem('lang')===null){
            localStorage.setItem("lang",navigator.language.substring(0,2));
        }
        this.lang = localStorage.getItem("lang");
    },
    methods: {
        lang_update: function(){
            localStorage.setItem("lang",this.lang);
            window.location.reload();
        },
        bt_go_mentions: function(){
            this.$router.push({path:'/mentions'});
        }
    }
}
</script>


<style scoped>
.lk-footer {
    margin-top: 60px;
    width: var(--WIDTH);
    margin-left: auto;
    margin-right: auto;
    height: 120px;
    display: grid;
    grid-template-columns: 2% 96% 2%;
    border-top: solid 1px #ccc;
}

.lk-footer-left {
    display: grid;
    align-items: center;
}

.lk-footer-center {
    display: grid;
    align-items: center;
    justify-items: center;
    text-align: center;
}

.lk-footer-center-content>a>img {
    height: 30px;
}

.lk-footer-center-content>label {
    font-size: 12px;
}

.lk-footer-right {
    justify-self: right;
    align-self: center;
}

.lk-footer-right>ul>li {
    display: block;
    cursor: pointer;
    margin-bottom: 5px;
}

.lk-footer-right>ul>li>i {
    color: var(--C1);
}

@media screen and (max-width: 800px) {
    .lk-footer {
        margin-top: 60px;
        height: unset;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: unset;
    }

    .lk-footer-left {
        justify-self: center;
        align-self: center;
    }

    .lk-footer-center {
        align-items: baseline;
    }

    .lk-footer-right {
        justify-self: center;
        align-self: center;
    }

    .lk-footer-right>ul>li {
        display: block;
        float: left;
        cursor: pointer;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
}
</style>