class translate {
    constructor() {
    }

    get(lang) {
        var ret = {};
        switch(lang){
            case "en":
                ret = {
                    g_enseigne:'Bauhaus',
                    g_wait:"Please wait...",
                    g_basket:"Basket",
                    g_stores:"Stores",
                    g_copyright:"All rights reserved",
                    g_language:"Language",
                    g_fr:"French",
                    g_en:"English",
                    g_de:"German",
                    g_nl:"Dutch",
                    g_available:"In stock",
                    g_unavailable:"Not available",
                    g_day:"day",
                    g_weekend:"weekend",
                    g_search:"Search",
                    g_period:"Rental period",
                    g_period_start:"Start",
                    g_period_end:"Return",
                    g_validate:"Validate",
                    g_total:"Total",
                    g_at:"to",
                    g_to:"at",
                    g_article:"article",
                    g_articles:"articles",
                    g_categories:"Categories",

                    home_title_h1:"For your work,<br/>RENT YOUR EQUIPMENT",
                    home_title_h2:"For a weekend or for a week,<br/>rent the equipment necessary for your work.",
                    home_title_h3:"Your store offers you a wide range of materials",
                    home_bt_gostore:"Go to store",

                    item_technical_info:"Technical information",
                    item_technical_doc:"Technical documentation",
                    item_bt_addbasket:"Add to basket",
                    item_bt_updatebasket:"Edit basket",
                    item_complete_pack:"To use it, complete the pack with",
                    item_more_accessories:"See more accessories",
                    item_products_associated:"Associated products",
                    item_basket_view:"View basket",
                    item_basket_continue:"Continue booking",
                    item_basket_added:"was added to your basket from",
                    item_basket_btlist:"Return to product list",

                    basket_nav_your_basket:"Your basket",
                    basket_nav_personal_info:"Personal information",
                    basket_nav_confirm:"Confirmation",
                    basket_nav_payment:"Payment",
                    basket_recap_title:"Order summary",
                    basket_recap_store:"Your store",
                    basket_recap_rental_start:"Rental start",
                    basket_recap_rental_end:"End of rental",
                    basket_recap_subtotal:"Subtotal",
                    basket_recap_total:"Total",
                    basket_recap_total_legend:"To cash in store",
                    basket_recap_total_legend_web:"Amount debited corresponding to your rental period",
                    basket_recap_deposit:"Security deposit",
                    basket_recap_deposit_legend:"To cash in store",
                    basket_recap_deposit_legend_web:"A bank imprint will be made on your card at the time of payment. You will not be charged.",
                    basket_your_make_reservation:"Place order",
                    basket_info_thirdtype:"Customer type",
                    basket_info_part:"Individual",
                    basket_info_pro:"Professional",
                    basket_info_firstname:"First name",
                    basket_info_lastname:"Name",
                    basket_info_address:"Street",
                    basket_info_zipcode:"Zip code",
                    basket_info_city:"City",
                    basket_info_phone:"Mobile",
                    basket_info_email:"Email",
                    basket_info_company:"Company name",
                    basket_info_tva_number:"VAT number",
                    basket_info_cni_number:"ID card number",
                    basket_info_fields:"All fields are required",
                    basket_info_confirm:"Confirm information",
                    basket_confirm_video_link:"Click here to watch the video",
                    basket_confirm_doc_link:"Click here to consult the technical documentation",
                    basket_confirm_cgr_label:"I confirm that I have read how the equipment works and I accept",
                    basket_confirm_cgr_link: "general rental conditions",
                    basket_confirm_payment:"Proceed to payment",
                    basket_nav_btlist:"Return to product list",

                    payment_success_title:'Thank you for your reservation',
                    payment_success_legend:'The confirmation of your reservation has been sent to you by email',
                    payment_error_title:'An error has occurred',
                    payment_error_legend:'We invite you to contact your store to finalize your reservation',
                    payment_nav_home:"Return to home"
                };
                break;
            case "de":

                break;
            case "nl":
                ret = {
                    g_enseigne:'Bauhaus',
                    g_wait:"Een ogenblik geduld...",
                    g_basket:"Winkelmand",
                    g_stores:"Winkels",
                    g_copyright:"Alle rechten voorbehouden",
                    g_taal:"Taal",
                    g_fr:"Frans",
                    g_en:"Engels",
                    g_de:"Duits",
                    g_nl:"Nederlands",
                    g_available:"Op voorraad",
                    g_unavailable:"Niet beschikbaar",
                    g_jour:"dag",
                    g_weekend:"weekend",
                    g_search:"Zoeken",
                    g_period:"Huurperiode",
                    g_period_start:"Start",
                    g_period_end:"Retour",
                    g_validate:"Valideren",
                    g_total:"Totaal",
                    g_at:"naar",
                    g_to:"bij",
                    g_article:"artikel",
                    g_articles:"artikelen",
                    g_categories:"Categorieën",

                    home_title_h1:"Voor uw werk,<br/>HUUR UW UITRUSTING",
                    home_title_h2:"Voor een weekend of een week<br/>huur je de uitrusting die nodig is voor je werk.",
                    home_title_h3:"Uw winkel biedt u een breed scala aan materialen",
                    home_bt_gostore:"Ga naar winkel",

                    item_technical_info:"Technische informatie",
                    item_technical_doc:"Technische documentatie",
                    item_bt_addbasket:"Toevoegen aan winkelwagen",
                    item_bt_updatebasket:"Bewerk winkelmandje",
                    item_complete_pack:"Om het te gebruiken, voltooi het pakket met",
                    item_more_accessories:"Bekijk meer accessoires",
                    item_products_associated:"Bijbehorende producten",
                    item_basket_view:"Bekijk winkelmandje",
                    item_basket_continue:"Ga door met boeken",
                    item_basket_added:"werd aan uw winkelmandje toegevoegd vanaf",
                    item_basket_btlist:"Terug naar productlijst",

                    basket_nav_your_basket:"Uw winkelmandje",
                    basket_nav_personal_info:"Persoonlijke informatie",
                    basket_nav_confirm:"Bevestiging",
                    basket_nav_payment:"Betaling",
                    basket_recap_title:"Besteloverzicht",
                    basket_recap_store:"Uw winkel",
                    basket_recap_rental_start:"Huurstart",
                    basket_recap_rental_end:"Einde huur",
                    basket_recap_subtotal:"Subtotaal",
                    basket_recap_total:"Totaal",
                    basket_recap_total_legend:"Om te verzilveren in de winkel",
                    basket_recap_total_legend_web:"Afgeschreven bedrag overeenkomend met uw huurperiode",
                    basket_recap_deposit:"Waarborgsom",
                    basket_recap_deposit_legend:"Om in de winkel te verzilveren",
                    basket_recap_deposit_legend_web:"Er wordt een bankafdruk op uw kaart gemaakt op het moment van betaling. Er worden geen kosten in rekening gebracht.",
                    basket_your_make_reservation:"Plaats bestelling",
                    basket_info_thirdtype:"Klanttype",
                    basket_info_part:"Individueel",
                    basket_info_pro:"Professioneel",
                    basket_info_firstname:"Voornaam",
                    basket_info_lastname:"Naam",
                    basket_info_address:"Straat",
                    basket_info_zipcode:"Postcode",
                    basket_info_city:"Stad",
                    basket_info_phone:"Mobiel",
                    basket_info_email:"E-mail",
                    basket_info_company:"Bedrijfsnaam",
                    basket_info_tva_number:"BTW-nummer",
                    basket_info_cni_number:"ID Kaart nummer",
                    basket_info_fields:"Alle velden zijn verplicht",
                    basket_info_confirm:"Informatie bevestigen",
                    basket_confirm_video_link:"Klik hier om de video te bekijken",
                    basket_confirm_doc_link:"Klik hier om de technische documentatie te raadplegen",
                    basket_confirm_cgr_label:"Ik bevestig dat ik heb gelezen hoe de apparatuur werkt en ik ga ermee akkoord",
                    basket_confirm_cgr_link: "algemene huurvoorwaarden",
                    basket_confirm_payment:"Ga door naar betaling",
                    basket_nav_btlist:"Terug naar productlijst",

                    payment_success_title:'Bedankt voor uw reservering',
                    payment_success_legend:'De bevestiging van uw reservering is per e-mail naar u verzonden',
                    payment_error_title:'Er is een fout opgetreden',
                    payment_error_legend:'Wij nodigen u uit om contact op te nemen met uw winkel om uw reservering af te ronden',
                    payment_nav_home:"Terug naar huis"
                };
                break;
            default:
                ret = {
                    g_enseigne:'Bauhaus',
                    g_wait:"Veuillez patienter...",
                    g_basket:"Panier",
                    g_stores:"Magasins",
                    g_copyright:"Tous droits réservés",
                    g_language:"Langue",
                    g_fr:"Français",
                    g_en:"Anglais",
                    g_de:"Allemand",
                    g_nl:"Néerlandais",
                    g_available:"En stock",
                    g_unavailable:"Non disponible",
                    g_jour:"jour",
                    g_weekend:"week-end",
                    g_search:"Rechercher",
                    g_period:"Période de location",
                    g_period_start:"Départ",
                    g_period_end:"Retour",
                    g_validate:"Valider",
                    g_total:"Total",
                    g_at:"à",
                    g_to:"au",
                    g_article:"article",
                    g_articles:"articles",
                    g_categories:"Catégories",

                    home_title_h1:"Pour vos travaux,<br/>LOUER VOTRE MATÉRIEL",
                    home_title_h2:"Le temps d'un week-end ou pour une semaine,<br/>louer le matériel nécessaire pour vos travaux.",
                    home_title_h3:"Votre magasin vous propose un large panel de matériels",
                    home_bt_gostore:"Accéder à la boutique",

                    item_technical_info:"Informations techniques",
                    item_technical_doc:"Documentation technique",
                    item_bt_addbasket:"Ajouter au panier",
                    item_bt_updatebasket:"Modifier le panier",
                    item_complete_pack:"Pour son utilisation, complétez le pack avec",
                    item_more_accessories:"Voir plus d'accessoires",
                    item_products_associated:"Produits associés",
                    item_basket_view:"Voir le panier",
                    item_basket_continue:"Poursuivre la réservation",
                    item_basket_added:"a été ajouté à votre panier du",
                    item_basket_btlist:"Retour à la liste des produits",

                    basket_nav_your_basket:"Votre panier",
                    basket_nav_personal_info:"Informations personnelles",
                    basket_nav_confirm:"Confirmation",
                    basket_nav_payment:"Paiement",
                    basket_recap_title:"Récapitulatif de la commande",
                    basket_recap_store:"Votre magasin",
                    basket_recap_rental_start:"Début de location",
                    basket_recap_rental_end:"Fin de location",
                    basket_recap_subtotal:"Sous-Total",
                    basket_recap_total:"Total",
                    basket_recap_total_legend:"A encaisser en magasin",
                    basket_recap_total_legend_web:"Montant débité correspondant à votre période de location",
                    basket_recap_deposit:"Dépôt de garantie",
                    basket_recap_deposit_legend:"A encaisser en magasin",
                    basket_recap_deposit_legend_web:"Une empreinte bancaire sera faite sur votre carte au moment du paiement. Vous ne serez pas débité.",
                    basket_your_make_reservation:"Passer la commande",
                    basket_info_thirdtype:"Type de client",
                    basket_info_part:"Particulier",
                    basket_info_pro:"Professionnel",
                    basket_info_firstname:"Prénom",
                    basket_info_lastname:"Nom",
                    basket_info_address:"Rue",
                    basket_info_zipcode:"Code postal",
                    basket_info_city:"Ville",
                    basket_info_phone:"Mobile",
                    basket_info_email:"Email",
                    basket_info_company:"Raison sociale",
                    basket_info_tva_number:"Numéro de TVA",
                    basket_info_cni_number:"Numéro de carte d'identité",
                    basket_info_fields:"Tous les champs sont obligatoires",
                    basket_info_confirm:"Confirmer les informations",
                    basket_confirm_video_link:"Cliquez ici pour voir la vidéo",
                    basket_confirm_doc_link:"Cliquez ici pour consulter la documentation technique",
                    basket_confirm_cgr_label:"Je confirme avoir pris connaissance du fonctionnement des matériels et j'accepte",
                    basket_confirm_cgr_link:"conditions générales de location",
                    basket_confirm_payment:"Procéder au paiement",
                    basket_nav_btlist:"Retour à la liste des produits",

                    payment_success_title:'Merci pour votre réservation',
                    payment_success_legend:'La confirmation de votre réservation vous a été envoyé par email',
                    payment_error_title:'Une erreur est survenue',
                    payment_error_legend:'Nous vous invitons à contacter votre magasin pour finaliser votre réservation',
                    payment_nav_home:"Retour à l'accueil"
                }
                break;
        }
        return ret;
    }
}

export default new translate()