import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {DateTime} from 'luxon'

const app = createApp(App);
app.config.globalProperties.$filters = {
    uppercase(value){
        return value.toUpperCase();
    },
    money(value,mlang){
        var lang = mlang.replace('_','-');
        var vtype = "";
        switch(lang){
            case "fr-FR":
                vtype="EUR";
                break;
            default:
                vtype="EUR";
                break;
        }

        var formatter = new Intl.NumberFormat(lang, {style: 'currency',currency: vtype,minimumFractionDigits: 2,maximumFractionDigits:2});
        try{
            return formatter.format(value);
        }catch{
            return value;
        }
    },
    number(value,mlang){
        var lang = mlang.replace('_','-');
        var formatter = new Intl.NumberFormat(lang, {style: 'decimal',minimumFractionDigits: 2,maximumFractionDigits:2});
        try{
            return formatter.format(value);
        }catch{
            return value;
        }
    },
    mdate(value,mlang){
        var lang = mlang.replace('_','-');
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString(DateTime.DATE_SHORT);
    },
    mtime(value,mlang){
        var lang = mlang.replace('_','-');
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString("HH:mm");
    },
    mdatetime(value,mlang){
        var lang = mlang.replace('_','-');
        return DateTime.fromSQL(value,{locale:lang}).toLocaleString("DD/MM/YYYY HH:mm");
    },
    image(value){
        if (value!=''){
            try{
                var binary = '';
              var bytes = new Uint8Array(value);
              var len = bytes.byteLength;
              for (let i = 0; i < len; i++) {
                  binary += String.fromCharCode(bytes[i]);
              }
              return 'data:image/png;base64,'+btoa(binary);
            }catch(error){
                return "";
            } 
        }else{
            return "";
        }
    },
    mlang(value,lang){
        var tlang = '';
        if (lang=='fr'){tlang='fr_FR';}
        if (lang=='en'){tlang='en_GB';}
        if (lang=='de'){tlang='de_DE';}        
        if (lang=='nl'){tlang='nl_BE';}
        try{
            var o = JSON.parse(value);
            return (o[tlang] == undefined ? '' : o[tlang]);
        }catch(err){
            return "";
        }        
    }
}
  
app.use(router);
app.mount('#app');