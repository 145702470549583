<template>
    <div class="lk-header">
        <div class="lk-header-left">
            <div class="lk-header-left-logo">
                <img src="@/assets/castorama.png" alt="Castorama">
            </div>
        </div>
        <ul class="lk-header-right">
            
            
        </ul>
    </div>
</template>
  
<script>
import translate from "@/data/translate"

export default {
    data() {
        return {

        }
    },
    props: {
        agency: { logo: { data: [] } },
        cpt: Number
    },
    computed: {
        lg: function () {
            if (localStorage.getItem("lang")===null){
                return translate.get("fr");
            }else{
                return translate.get(localStorage.getItem("lang"));
            }      
        }
    },
    created: function () {

    },
    methods: {
        bt_list: function () {
            this.$router.push({ path: '/list' });
            // this.$router.go(-1);
        },
        bt_basket: function () {
            this.$router.push({ path: '/basket' });
            // this.$router.go(-1);
        }
    }
}
</script>

<style scoped>
.lk-header {
    width: var(--WIDTH);
    margin-left: auto;
    margin-right: auto;
    height: 90px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.lk-header-left {
    display: grid;
    align-items: center;
}

.lk-header-left-logo>img {
    height: 60px;
    cursor:pointer;
}

.lk-header-right {
    justify-self: right;
    align-self: center;
}

.lk-header-right>li {
    display: inline-block;
    margin-left: 20px;
    cursor: pointer;
}

.lk-header-right-button {
    display: grid;
    grid-template-columns: 24px auto;
    height: 40px;
    padding: 0px 20px 0px 20px;
}

.lk-header-right-button-logo {
    display: grid;
    width: 24px;
    height: 40px;
    align-items: center;
    justify-items: left;
}

.lk-header-right-button-content {
    display: grid;
    height: 40px;
    align-items: center;
    justify-items: left;
    cursor:pointer;
}

.lk-header-right-button-content>label {
    cursor:pointer;
}

.lk-header-right-basket {
    display: grid;
    grid-template-columns: 24px auto;
    height: 40px;
    padding: 0px 20px 0px 20px;
    background-color: var(--C1);
    color: #fff;
    border-radius: var(--RADIUS);
}

.lk-header-right-basket-logo {
    display: flex;
    width: 20px;
    height: 40px;
    align-items: center;
    justify-items: left;
}

.lk-header-right-basket-logo>i {
    color: #fff;
}

.lk-header-right-basket-content {
    display: flex;
    height: 40px;
    align-items: center;
    justify-items: left;
    color: #fff;
}

.lk-header-right-basket-content>label {
    color: #fff;
    cursor:pointer;
}

.lk-header-right-basket-logo>span {
    position: relative;
    left: -8px;
    top: -10px;
    background-color: #fff;
    color: var(--C1);
    font-size: 10px;
    border-radius: 10px;
    padding: 1px 5px;
    font-weight: bold;
}

@media screen and (max-width: 800px) {

    .lk-header {
        height: 70px;
    }

    .lk-header-left-logo>img {
        height: 35px;
    }

    .lk-header-right>li {
        display: inline-block;
        margin-left: 10px;
        cursor: pointer;
    }

    .lk-header-right-button {
        display: grid;
        grid-template-columns: 24px;
        height: 40px;
        padding: 0px 15px 0px 15px;
        border: solid 1px var(--GRAY);
        border-radius: var(--RADIUS);
    }

    .lk-header-right-button-logo {
        justify-items: center;
    }

    .lk-header-right-button-content {
        display: none;
    }

    .lk-header-right-basket {
        grid-template-columns: 24px;
        padding: 0px 15px 0px 15px;
    }

    .lk-header-right-basket-logo {
        justify-items: center;
    }

    .lk-header-right-basket-logo>i {
        color: #fff;
    }

    .lk-header-right-basket-content {
        display: none;
    }

    .lk-header-right-basket-logo>span {
        position: relative;
        left: 4px;
        top: -10px;
        background-color: #fff;
        color: var(--C1);
        font-size: 10px;
        border-radius: 10px;
        padding: 1px 5px;
        font-weight: bold;
    }
}
</style>