import axios from 'axios';
import config from './config';

class booking {
    constructor() {
        this.url = config.url();
    }
    async setting_get() {
        try {
            var data = {};
            var pkgheaders = {};
            var result = await axios.post(this.url + "/api/v2/setting", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }
    async product_list(release_date,release_time,return_date,return_time,gridperiod,token) {
        var data = {
            release_date:release_date,
            release_time:release_time,
            return_date:return_date,
            return_time:return_time,
            gridperiod:gridperiod,
            token:token,    
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/product/list", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async product_get(release_date,release_time,return_date,return_time,id) {
        var data = {
            release_date:release_date,
            release_time:release_time,
            return_date:return_date,
            return_time:return_time,
            id:id
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/product/get", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    
    async date_set(period,token) {
        var data = {period:period,token:token};
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/period/set", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_get(token,date_limit) {
        var data = {token:token,date_limit: date_limit};
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/get", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_set(token,product,accessories,release_date,release_time,return_date,return_time,gridperiod) {
        var data = {
            token:token,
            product:product,
            accessories:accessories,
            options:{release_date:release_date,
                release_time:release_time,
                return_date:return_date,
                return_time:return_time,
                gridperiod:gridperiod }
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/product/add", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_modify(token,product,accessories,release_date,release_time,return_date,return_time,gridperiod) {
        var data = {
            token:token,
            product:product,
            accessories:accessories,
            options:{release_date:release_date,
                release_time:release_time,
                return_date:return_date,
                return_time:return_time,
                gridperiod:gridperiod }
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/product/update", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_product_deleted(token,id_product) {
        var data = {
            token:token,
            id_product:id_product,
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/product/delete", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_product_modify(token,product) {
        var data = {
            token:token,
            product:product,
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/product/update", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_accessory_deleted(token,id_product,id_accessory) {
        var data = {
            token:token,
            id_product:id_product,
            id_accessory:id_accessory,
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/accessories/delete", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async basket_accessory_modify(token,product,accessory) {
        var data = {
            token:token,
            product:product,
            accessory:accessory
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/basket/accessories/update", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }

    async document_create(token,customer) {
        var data = {
            token:token,
            customer:customer,
        };
        try {
            var pkgheaders = { };
            var result = await axios.post(this.url + "/api/v2/document/create", data, { responseType: 'json', credentials: false, headers: pkgheaders });
            return (result.status == 200) ? result.data : { code: -1, message: '' };
        } catch (err) {
            return { code: -1, message: err };
        }
    }
}

export default new booking()