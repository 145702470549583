<template>
  <div>
    <uiheader :agency="agency" :cpt="basket.cpt"></uiheader>
    <div class="home-banniere">
      <div class="home-title">
        <h1 v-html="lg.home_title_h1"></h1>
        <h2 v-html="lg.home_title_h2"></h2>
        <button @click="nav_list" class="home-button">{{lg.home_bt_gostore}}</button>
      </div>
    </div>
    <div class="home-row">
      <h3 v-html="lg.payment_error_title"></h3>
    </div>
    <div class="home-row-catalog">
      <uicatalog :products="products"></uicatalog>
    </div>
    <uifooter></uifooter>
    <uiloading ref="ldg"></uiloading>
    <uidialogbox ref="dlg"></uidialogbox>
  </div>
</template>

<script>

import uiheader from "@/components/ui-header"
import uicatalog from "@/components/ui-catalog"
import uiloading from "@/components/ui-loading"
import uifooter from "@/components/ui-footer"
import uidialogbox from "@/components/ui-dialogbox"
import { store } from "@/data/store"
import { DateTime } from "luxon"
import apibooking from "@/data/booking"
import translate from "@/data/translate"

export default {
  components: {
    uiheader, uicatalog, uifooter, uiloading, uidialogbox
  },
  data() {
    return {
      release_date: DateTime.now().toFormat('yyyy-MM-dd'),
      release_time: '08:00',
      return_date: DateTime.now().toFormat('yyyy-MM-dd'),
      return_time: '16:00',
      products: [],
      agency: {},
      setting: {},
      token: "",
      store,
      basket: { cpt: 0, products: [] }
    }
  },
  computed: {
    lg: function () {
      if (localStorage.getItem("lang")===null){
        localStorage.setItem("lang","fr");
        return translate.get("fr");
      }else{
        return translate.get(localStorage.getItem("lang"));
      }      
    }
  },
  created: async function () {
    this.token = store.get_token();
    console.log(this.$refs);
    var ret = await apibooking.setting_get();
    if (ret.code == 0) {
      this.setting = ret.data.setting;
      this.agency = ret.data.agency;
    }

    await this.refresh_ui();
  },
  methods: {
    refresh_ui: async function () {
      var tmain = this;
      await tmain.$refs.ldg.open({message:'Veuillez patienter...'});
      var info = await apibooking.product_list(this.release_date, this.release_time, this.return_date, this.return_time);
      var ret2 = await apibooking.basket_get(this.token);      
      if (ret2.code == 0) {
        this.basket = ret2.data;

        if(this.basket.product_deleted){
          var dlgoptions= {
            title:'Produit plus disponible',
            message: 'Certains produits ne sont plus disponible '+this.basket.msg_deleted+' ils ont été supprimé de votre panier',
            buttons:[{label:'Confirmer'}]
        };
        await this.$refs.dlg.show(dlgoptions);
        delete this.basket.msg_deleted;
        delete this.basket.product_deleted;
        }
      }
      if (info.code == 0) {
        this.families = info.data.families;
        this.subfamilies = info.data.subfamilies;
        var products = info.data.products;
        var groups = [];
        for (var f of this.families) {
          var item = {
            id: f.id,
            is_selected: false,
            id_family: f.id,
            web_label: f.web_label,
            is_expanded: true,
            gtype: 'family'
          }
          groups.push(item);
          for (var sf of this.subfamilies.filter(x => x.id_family == f.id)) {
            var sitem = {
              id: sf.id,
              is_selected: false,
              id_family: sf.id_family,
              web_label: sf.web_label,
              gtype: 'subfamily'
            }
            groups.push(sitem);
          }
        }
        this.groups = groups;
      }
      await tmain.$refs.ldg.close();
      this.products = products.slice(0, 4);
    },
    nav_list: function () {
      this.$router.push({ path: '/list' });
    }
  }
}
</script>

<style scoped>
.home-banniere {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  background-image: url("@/assets/banniere.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--RADIUS);
  height: 450px;
  display: grid;
}

.home-title {
  width: 60%;
  /* background-color: red; */
  justify-self: left;
  padding-left: 80px;
  align-self: center;
  border-radius: var(--RADIUS);
  position: relative;
}

.home-title>h1 {
  font-size: 34px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.home-title>h2 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 60px;
}

.home-button {
  background-color: var(--C1);
  padding: 10px 35px;
  color: #fff;
  border: none;
  border-radius: var(--RADIUS);
  cursor: pointer;
}

.home-button:hover {
  background-color: var(--C2);
}

.home-row {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.home-row>h3 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.home-row-catalog {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  background-color: var(--GRAY);
  border-radius: var(--RADIUS);
  padding: 20px;
}

@media screen and (max-width: 800px) {

  .home-banniere {
    width: var(--WIDTH);
    margin-left: auto;
    margin-right: auto;
    background-image: url("@/assets/banniere.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--RADIUS);
    height: 400px;
    display: grid;
  }

  .home-title {
    padding-left: 20px;
    width: calc(100% - 40px);
  }

  .home-title>h1 {
    font-size: 24px;
  }

  .home-title>h2 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 60px;
  }

  .home-button {
    background-color: var(--C1);
    padding: 10px 35px;
    color: #fff;
    border: none;
    border-radius: var(--RADIUS);
    cursor: pointer;
  }

  .home-button:hover {
    background-color: var(--C2);
  }

  .home-row-catalog {
    padding: 10px 10px 10px 0px;
  }
}
</style>