<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
    }
  },
  created(){
    
  }
}
</script>

<style src="./lokisi.css"></style>
<style src="./lokisi.icons.css"></style>