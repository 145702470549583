class config {
    constructor() {
        this.is_production = false;
    }

    is_dev() { return true; }

    url() {
        return 'https://castorama.lokisi.rent/';
    }
}

export default new config()