<template>
    <transition name="fade">
        <div v-if="is_visible">
            <div class='lk-dialogbox-background'></div>
            <div class='lk-dialogbox'>
                <div class="lk-dialogbox-header">
                    <div class="lk-dialogbox-header-title">
                        <label>{{ setting.title }}</label>
                    </div>
                </div>
                <div class="lk-dialogbox-content">
                    <div v-html="setting.message"></div>
                </div>
                <div class="lk-dialogbox-buttons">
                    <ul>
                        <li v-for="bt of setting.buttons" :key="bt.label" @click="button_click(bt)">{{ bt.label }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    data() {
        return {
            is_visible: false
        };
    },
    created() {

    },
    methods: {
        show: function (opts = {}) {
            this.setting = opts;
            this.is_visible = true;
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve;
                this.rejectPromise = reject;
            });
        },
        button_click: function (bt) {
            this.is_visible = false;
            this.resolvePromise(bt);
        }
    }
}
</script>

<style>
.lk-dialogbox-background {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.3;
    z-index: 1;
}

.lk-dialogbox {
    position: absolute;
    width: 50%;
    left: 25%;
    top: 10%;
    display: grid;
    grid-template-rows: 80px auto 100px;
    z-index: 1;
}

.lk-dialogbox-header {
    background-color: var(--C1);
    border-top-right-radius: var(--RADIUS);
    border-top-left-radius: var(--RADIUS);
    display: grid;
}

.lk-dialogbox-header-title {
    align-self: center;
    justify-self: center;
}

.lk-dialogbox-header-title>label {
    color: #fff;
    font-size: 20px;
}

.lk-dialogbox-content {
    background-color: #fff;
    padding: 20px;
}

.lk-dialogbox-buttons {
    background-color: #fff;
    border-bottom-right-radius: var(--RADIUS);
    border-bottom-left-radius: var(--RADIUS);
    display: grid;
    align-items: center;
    justify-items: center;
}

.lk-dialogbox-buttons>ul {
    float: center;
}

.lk-dialogbox-buttons>ul>li {
    padding: 10px 15px;
    background-color: var(--C1);
    cursor: pointer;
    color: #fff;
    border-radius: var(--RADIUS);
}

.lk-dialogbox-buttons>ul>li:hover {
    background-color: var(--C2);
}

@media screen and (max-width: 800px) {
    .lk-dialogbox {
        position:fixed;
        width: 100%;
        height:100%;
        left: 0%;
        top: 0%;
        display: grid;
        grid-template-rows: 80px auto 100px;
    }
}
</style>