<template>
    <div class="lok-products-list">
        <div class="lok-product-item" v-for="p of products" :key="p.id" @click="bt_item_select(p.id)">
            <div class="lok-product-item-logo">
                <img :src="p.url" :alt="$filters.mlang(p.web_label,code_lang)">
            </div>
            <div class="lok-product-item-title">
                <label>{{$filters.mlang(p.web_label,code_lang)}}</label>
            </div>
            <div class="lok-product-item-stock">
                <label v-if="p.qtydispo>0"><i class='fa fa-circle'></i> {{lg.g_available}} </label>
                <label v-if="p.qtydispo<1"><i class='fa fa-circle' style="color:red"></i> {{lg.g_unavailable}} </label>
            </div>
            
            <div class="lok-product-item-actions">
                <!--<div class="lok-product-item-price">
                    <label><span>{{$filters.money(p.selling_price,store.lang)}}</span>/{{p.typeperiod}}</label>
                </div>-->
                <div v-if="p.producttype=='MATERIAL'" class="lok-product-item-price">
                <label><span>{{ $filters.money(p.selling_price,store.lang) }}</span> / {{lg['g_'+p.typeperiod]}}</label>
                <!--<span>{{ $filters.money(p.total_line,store.lang) }} total</span>-->
                </div>
                <div v-if="p.producttype!='MATERIAL'" class="lok-product-item-price">
                    <label><span>{{ $filters.money(p.selling_price,store.lang) }}</span></label>
                </div>
                <div class="lok-product-item-add">
                    <i v-if="p.qtydispo>0" class='fa fa-shopping-cart'></i>
                    <i v-if="p.qtydispo<1" class='fas fa-search'></i>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import {store} from "@/data/store"
import translate from "@/data/translate"

export default {
    data() {
        return {
            store
        }
    },
    props: { 
        products:Array
    },
    created: function(){

    },  
    computed: {
        lg: function () {
            if (localStorage.getItem("lang")===null){
                return translate.get("fr");
            }else{
                return translate.get(localStorage.getItem("lang"));
            }
        },
        code_lang:function(){
            return localStorage.getItem("lang");
        }

    },
    methods:{
        bt_item_select: function(id){
          this.$router.push({path:'/item/'+id});
        }
    }
}
</script>

<style scoped>

    .lok-products-list{
        display:grid;
        width:100%;
        grid-template-columns: repeat(6,1fr);
        gap:20px;
    }

    .lok-product-item{
        background-color: #fff;
        border-radius: var(--RADIUS);
        padding:15px;
        display:grid;
        grid-template-rows: 150px 50px 20px 40px;
        cursor: pointer;
    }

    .lok-product-item-logo{
        justify-self: center;
    }

    .lok-product-item-logo>img{
        width:auto;
        max-width:140px;
        height:140px;
        object-fit:cover;
    }

    .lok-product-item-title{
        text-align:left;
    }

    .lok-product-item-title>label{
        font-size:14px;
        font-weight: bold;
        text-transform: lowercase;
        
    }

    .lok-product-item-title>label::first-letter{
        text-transform: capitalize;
    }

    .lok-product-item-stock>label{
        text-align:left;
        font-size:12px;
    }

    .lok-product-item-stock>label>i{
        color:green;
    }

    .lok-product-item-actions{
        display:grid;
        grid-template-columns: repeat(2,1fr);
    }

    .lok-product-item-price{
        display:grid;
        align-items: center;
        justify-items: left;
    }

    .lok-product-item-price>label{
        display:inline-block;
        font-size:12px;
    }

    .lok-product-item-price>label>span{
        display:inline-block;
        font-weight: bold;
        font-size:15px;
    }

    .lok-product-item-add{
        display:grid;
        align-items: center;
        justify-items: right;
    }

    .lok-product-item-add>i{
        color:#fff;
        background-color:var(--C1);
        padding:10px;
        border-radius:5px;
    }

    @media screen and (max-width: 1920px) {
        .lok-products-list{
            grid-template-columns: repeat(4,1fr);
        }
    }

    @media screen and (max-width: 800px) {
        .lok-products-list{
            display:grid;
            padding-left:10px;
            grid-template-columns: repeat(2,1fr);
            gap:10px;
        }

        .lok-product-item{
            padding:10px;
        }

        .lok-product-item-title>label{
            font-size:12px;
        }

        .lok-product-item-price>label{
            font-size:10px;
        }

        .lok-product-item-price>label>span{
            font-size:12px;
        }

        .lok-product-item-actions{
            grid-template-columns: 60% 40%;
        }
    }

</style>