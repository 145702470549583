<template>
    <div class="lk-loading" v-if="is_visible">
        <div class="lk-loading-background"></div>
        <div class="lk-loading-content">
            <label><i style="color:var(--C1)" class='fas fa-circle-notch fa-spin'></i> {{lg.g_wait}}</label>
        </div>
    </div>
  </template>
  
  <script>
  
  import translate from "@/data/translate"
  export default {
    data() {
      return {
        is_visible:false,
        message:'Please wait'
      }
    },
    created: function(){

    },  
    computed: {
        lg: function () {
            if (localStorage.getItem("lang")===null){
                return translate.get("fr");
            }else{
                return translate.get(localStorage.getItem("lang"));
            }
        },
        code_lang:function(){
            return localStorage.getItem("lang");
        }
    },
    methods:{
        open: function(opts={}){
            this.is_visible=true;
            if (opts.message!=undefined){
                this.message = opts.message;
            }
        },
        close: function(){
            this.is_visible=false;
        },
    }
  }
  </script>

  <style scoped>

    .lk-loading{
        position:absolute;
        width:100%;
        height:100%;
        top:0px;
        left:0px;
        display: grid;
        align-items: center;
        justify-items: center;
        z-index:9;
    }

    .lk-loading-background{
        position:fixed;
        width:100%;
        height:100%;
        top:0px;
        left:0px;
        background-color: #fff;
        opacity:0.5;
    }

    .lk-loading-content{
        display: grid;
        width: 100%;
        height: 200px;
        justify-items: center;
        align-items: center;
        background-color: #fff;
        position: absolute;
        border-top: solid 1px #ccc;
        border-bottom: solid 1px #ccc;
    }

    .lk-loading-content>label{
        font-size:20px;
    }

  </style>