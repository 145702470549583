<template>
  <div>
    <uiheader :agency="agency" :cpt="basket.cpt"></uiheader>
    <div class="ment-banniere">
      <div class="ment-title">
        <h1>Mentions légales</h1>
        <button @click="nav_list" class="ment-button">Retour à la sélection du magasin</button>
      </div>
    </div>
    <div class="ment-row">
      <h3>Propriété du site</h3>
      <p>
        Le site castorama.lokisi.rent est la propriété de la société ACCEN Informatique, inscrite au RCS Lyon sour le numéro 538 705 989 00035.<br/>
        ACCEN Informatique - 3. rue Louis Gattefossé - 69800 Saint-Priest<br/>
        Téléphone : 04 37 42 39 94<br/>
        Courriel : support@lokisi.com
      </p>
    </div>
    <div class="ment-row">
      <h3>Hébergement</h3>
      <p>
        Les données de ce site Internet sont hébergées en France auprès du prestataire OVH dont le siège social est situé à l’adresse suivante : 2 Rue Kellermann, 59100 Roubaix.
      </p>
    </div>
    <div class="ment-row">
      <h3>Conception et réalisation</h3>
      <p>
        ACCEN Informatique – 3. rue Louis Gattefossé 69800 Saint-Priest<br/>
        Site internet : www.lokisi.com
      </p>
    </div>
    <div class="ment-row">
      <h3>Propriété intellectuelle</h3>
      <p>
        Tout le contenu du présent site est la propriété exclusive de la société à l’exception des marques, logos ou contenus appartenant à d’autres sociétés partenaires ou auteurs. Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site, sans l’autorisation d’ACCEN Informatique ou de son représentant est prohibée et pourra entraînée des actions et poursuites judiciaires telles que prévues par le code de la propriété intellectuelle.
      </p>
    </div>
    <div class="ment-row">
      <h3>Limitation de responsabilité</h3>
      <p>
        Les photos sont non contractuelles. La responsabilité d’ACCEN Informatique ne saurait être engagée quant aux éventuelles erreurs ou omissions présentes sur le site. En aucun cas, ACCEN Informatique ne prendra en charge l’indemnisation de dommages et intérêts immatériels, directs ou indirects, subis par l’utilisateur, tels que manques à gagner, préjudice d’exploitation ou perte de bénéfice. En outre, ACCEN Informatique ne peut être tenu pour responsable de l’impossibilité momentanée d’accéder au site castorama.lokisi.rent, si la cause en est une interruption des services des fournisseurs d’électricité, de télécommunication, d’interruption du fonctionnement des réseaux du fournisseur d’accès à internet, de défaillance du matériel de réception ou de la ligne de l’abonné, ainsi que tout cas de force majeure ou fait d’autrui.
      </p>
    </div>
    <div class="ment-row">
      <h3>Informatique et libertés</h3>
      <p>
        Conformément à la loi « Informatique et Libertés » du 6 janvier 1978 modifiée en 2004, vous bénéficiez d’un droit d’accès et de rectification aux informations qui vous concernent que vous pouvez exercer en adressant un e-mail à votre magasin Castorama.
      </p>
    </div>
    <uifooter></uifooter>
    <uiloading ref="ldg"></uiloading>
    <uidialogbox ref="dlg"></uidialogbox>
  </div>
</template>

<script>

import uiheader from "@/components/ui-header"
import uiloading from "@/components/ui-loading"
import uifooter from "@/components/ui-footer"
import uidialogbox from "@/components/ui-dialogbox"
import { store } from "@/data/store"
import { DateTime } from "luxon"
import apibooking from "@/data/booking"
import translate from "@/data/translate"

export default {
  components: {
    uiheader,  uifooter, uiloading, uidialogbox
  },
  data() {
    return {
      release_date: DateTime.now().toFormat('yyyy-MM-dd'),
      release_time: '08:00',
      return_date: DateTime.now().toFormat('yyyy-MM-dd'),
      return_time: '16:00',
      products: [],
      agency: {},
      setting: {},
      token: "",
      store,
      basket: { cpt: 0, products: [] }
    }
  },
  computed: {
    lg: function () {
      if (localStorage.getItem("lang")===null){
        localStorage.setItem("lang","fr");
        return translate.get("fr");
      }else{
        return translate.get(localStorage.getItem("lang"));
      }      
    }
  },
  created: async function () {
    this.token = store.get_token();
    var ret = await apibooking.setting_get();
    if (ret.code == 0) {
      this.setting = ret.data.setting;
      this.agency = ret.data.agency;
    }
    // document.querySelector('meta[name="description"]').setAttribute("content","Castorama "+this.setting.city+" - Location de matériels en ligne à "+this.setting.city);
    // document.querySelector('meta[name="keywords"]').setAttribute("content","Castorama "+this.setting.city+", location, location de matériels, réservation en ligne, lokisi, loxam");

    await this.refresh_ui();
  },
  methods: {
    refresh_ui: async function () {
      var tmain = this;
      await tmain.$refs.ldg.open({message:'Veuillez patienter...'});
      var ret2 = await apibooking.basket_get(this.token);      
      if (ret2.code == 0) {
        this.basket = ret2.data;

        if(this.basket.product_deleted){
          var dlgoptions= {
            title:'Produit plus disponible',
            message: 'Certains produits ne sont plus disponible '+this.basket.msg_deleted+' ils ont été supprimé de votre panier',
            buttons:[{label:'Confirmer'}]
        };
        await this.$refs.dlg.show(dlgoptions);
        delete this.basket.msg_deleted;
        delete this.basket.product_deleted;
        }
      }
      await tmain.$refs.ldg.close();
    },
    nav_list: function () {
      this.$router.push({ path: '/' });
    }
  }
}
</script>

<style scoped>
.ment-banniere {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  background-image: url("@/assets/banniere.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: var(--RADIUS);
  height: 200px;
  display: grid;
}

.ment-title {
  width: 60%;
  /* background-color: red; */
  justify-self: left;
  padding-left: 80px;
  align-self: center;
  border-radius: var(--RADIUS);
  position: relative;
}

.ment-title>h1 {
  font-size: 34px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 20px;
  letter-spacing: 3px;
}

.ment-title>h2 {
  font-size: 14px;
  color: #fff;
  margin-bottom: 60px;
}

.ment-button {
  background-color: var(--C1);
  padding: 10px 35px;
  color: #fff;
  border: none;
  border-radius: var(--RADIUS);
  cursor: pointer;
}

.ment-button:hover {
  background-color: var(--C2);
}

.ment-row {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.ment-row>h3 {
  text-align: left;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom:15px;
  color:var(--C1);
}

.ment-row-catalog {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  background-color: var(--GRAY);
  border-radius: var(--RADIUS);
  padding: 20px;
}

@media screen and (max-width: 800px) {

  .ment-banniere {
    width: var(--WIDTH);
    margin-left: auto;
    margin-right: auto;
    background-image: url("@/assets/banniere.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: var(--RADIUS);
    height: 200px;
    display: grid;
  }

  .ment-title {
    padding-left: 20px;
    width: calc(100% - 40px);
  }

  .ment-title>h1 {
    font-size: 24px;
  }

  .ment-title>h2 {
    font-size: 14px;
    color: #fff;
    margin-bottom: 60px;
  }

  .ment-button {
    background-color: var(--C1);
    padding: 10px 35px;
    color: #fff;
    border: none;
    border-radius: var(--RADIUS);
    cursor: pointer;
  }

  .ment-button:hover {
    background-color: var(--C2);
  }

  .ment-row-catalog {
    padding: 10px 10px 10px 0px;
  }
}
</style>