const axios = require('axios');

class geocode{

    constructor(){}

    async get_gps(address,zipcode,city){
        var gps = {lat:0,lon:0};

        try{
            var address_enc = encodeURIComponent(address+' '+zipcode+' '+city);
            var url = `https://nominatim.openstreetmap.org/search?format=json&q=${address_enc}`;
            var rep = await axios.get(url,{ responseType: 'json' });
            if (rep.status==200){
                if (rep.data.length>0){
                    gps = {lat:parseFloat(rep.data[0].lat),lon:parseFloat(rep.data[0].lon)};
                }else{
                    var address_enc2 = encodeURIComponent(zipcode+' '+city);
                    var url2 = `https://nominatim.openstreetmap.org/search?format=json&q=${address_enc2}`;
                    var rep2 = await axios.get(url2,{ responseType: 'json' });
                    if (rep2.status==200){
                        if (rep2.data.length>0){
                            gps = {lat:parseFloat(rep2.data[0].lat),lon:parseFloat(rep2.data[0].lon)};
                        }
                    }       
                }
            }
        }catch(err){
            console.log();
        }
        
        return gps;   
    }

    get_distance(coord1, coord2) {
        const deltaX = coord2.lat - coord1.lat;
        const deltaY = coord2.lon - coord1.lon;
        return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
    }

    get_closest_gps(gps, list) {
        if (!gps || !list || list.length === 0) {
          return [];
        }     
        let ret = [];
        let distance_min = 300;
      
        for (const coord of list) {
          var distance = this.get_distance(gps, coord);
          coord.distance = distance;
          if (coord.distance<=distance_min){
            ret.push(coord);
          }          
        }

        return ret.sort((a,b)=>a.distance - b.distance);
    }
}

export default new geocode;