import {reactive} from 'vue'

export const store = reactive({
    title:'LoKisi',
    lang:'fr_FR',
    token:'',
    set_language(value) {
        this.lang = value;
        localStorage.setItem("lx-lg",JSON.stringify(value));
    },
    get_language(){
        var value = {};
        if (localStorage.getItem("lx-lg")!=null){
            value = JSON.parse(localStorage.getItem("lx-lg"));
        }
        return value;
    },
    set_token(value) {
        this.token = value;
        localStorage.setItem("lx-token",JSON.stringify(value));
    },
    get_token(){
        var value = {};
        if (localStorage.getItem("lx-token")!=null){
            value = JSON.parse(localStorage.getItem("lx-token"));
        }
        return value;
    },
})