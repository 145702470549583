<template>
  <div>
    <uiheader :agency="{company:'',logo: {data:[]}}" :cpt="0"></uiheader>
    <div class="site-banniere">
      <div class="site-banniere-logo">
        <img src="@/assets/banner.jpg" alt="">
      </div>
      <div class="site-banniere-info">
        <div class="site-banniere-info-title">
          <label>Choisissez votre magasin</label>
        </div>
        <div class="site-banniere-info-search">
          <label>Rechercher votre ville</label>
          <div class="site-banniere-info-search-input">
            <input type="text" v-model="search_text" placeholder="Rechercher" @keyup.enter="bt_search">
            <i @click="bt_search" class="fa fa-search"></i>
          </div>          
        </div>
        <div class="site-banniere-info-list">
          <a  v-for="s of stores_filtered" :key="s.company" :href="s.url">
            <div class="site-banniere-info-listitem">
              <label>{{ s.company }}</label>
              <span>{{ s.address }}</span>
              <span>{{ s.zipcode }} {{ s.city }}</span>
            </div>
          </a>
        </div>
      </div>
    </div>
    <div class="site-row">
      <h3>location de matériel : équipez-vous comme un pro pendant vos travaux</h3>
    </div>
    <div class="site-row">
      <label>Besoin d’une scie électrique le temps d’un week-end pour découper votre nouveau carrelage de cuisine ? Une décolleuse à papier peint vous serait bien utile quelques heures pour accélérer le relooking de votre salon ? Grâce à notre service de location de matériel, vous profitez d’outils de professionnels juste le temps de vos travaux !</label>
    </div>
    <div class="site-row">
      <label>Comment ça marche ?</label>
      <label style="font-weight:bold;padding-left:40px;">1 - Effectuez votre réservation en ligne : Sélectionnez le magasin souhaité pour le retrait et renseignez votre besoin (type d’outil, dates souhaitées).</label>
      <label style="font-weight:bold;padding-left:40px;">2 - Retirez votre matériel à la date programmée dans votre magasin.</label>
      <label style="font-weight:bold;padding-left:40px;">3 - Rapportez le matériel en magasin puis réglez votre location.</label>
    </div>
    <div class="site-row">
      <label>À bientôt chez Castorama.</label>
    </div>
    <uifooter></uifooter>
    <uiloading ref="ldg"></uiloading>
    <uidialogbox ref="dlg"></uidialogbox>
  </div>
</template>

<script>

import uiheader from "@/components/ui-header"
import uiloading from "@/components/ui-loading"
import uifooter from "@/components/ui-footer"
import uidialogbox from "@/components/ui-dialogbox"
import { store } from "@/data/store"
import translate from "@/data/translate"
import geocode from "@/data/geocode"

export default {
  components: {
    uiheader,  uifooter, uiloading, uidialogbox
  },
  data() {
    return {
      store,
      stores:[],
      stores_filtered:[],
      search_text:''
    }
  },
  computed: {
    lg: function () {
      if (localStorage.getItem("lang")===null){
        localStorage.setItem("lang","fr");
        return translate.get("fr");
      }else{
        return translate.get(localStorage.getItem("lang"));
      }      
    }
  },
  created: async function () {
    document.title = "Castorama - Online Booking";
  },
  mounted: async function(){
    await this.refresh_ui();
  },
  methods: {
    refresh_ui: async function () {
      var tmain = this;
      await tmain.$refs.ldg.open({message:'Veuillez patienter...'});
      fetch("https://castorama.lokisi.rent/agencies.json").then(r=> r.json()).then(async(json) => { 
        tmain.stores = json; 
        tmain.stores_filtered = json;
        await tmain.$refs.ldg.close();
      });        
    },
    bt_search: async function(){
      var mygps = await geocode.get_gps('','',this.search_text);
      this.stores_filtered = geocode.get_closest_gps(mygps,this.stores);
    }
  }
}
</script>

<style scoped>
.site-banniere {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  height: 450px;
  display: grid;
  grid-template-columns: auto 400px;
  gap:50px;
}

.site-banniere-logo>img{
  height:450px;
  width:100%;
  object-fit: cover;
  border-radius: var(--RADIUS);
}

.site-banniere-info{
  display:grid;
  width:100%;
  height:100%;
  grid-template-rows: 40px 80px 330px;
}

.site-banniere-info-title>label{
  font-weight: 600;
  font-size:18px;
}

.site-banniere-info-search>label{
  text-transform: uppercase;
  font-weight: 600;
  font-size:12px;
  padding-left:5px;
}

.site-banniere-info-search-input{
  width:100%;
  display:grid;
  grid-template-columns: auto 40px;
}
.site-banniere-info-search-input>input{
  padding:7px 15px;
  border-top-left-radius: var(--RADIUS);
  border-bottom-left-radius: var(--RADIUS);
  border:solid 1px #ccc;
  width:100%;
}

.site-banniere-info-search-input>i{
  height:40px;
  background-color: var(--C1);
  border-top-right-radius: var(--RADIUS);
  border-bottom-right-radius: var(--RADIUS);
  text-align:center;
  line-height: 40px;
  color:#fff;
  cursor: pointer;
}

.site-banniere-info-search-input>i:hover{
  background-color: var(--C2);
  cursor: pointer;
}

.site-banniere-info-list{
  overflow-y:auto;
  overflow-x:hidden;
}

.site-banniere-info-list>a{
  text-decoration: none;
}

.site-banniere-info-listitem{
  padding:10px 15px;
  border:solid 1px #ccc;
  margin-bottom:10px;
  border-radius:var(--RADIUS);
  cursor: pointer;
}

.site-banniere-info-listitem:hover{
  background-color: var(--C3);
  cursor: pointer;
}

.site-banniere-info-listitem>label{
  font-weight: 600;
  cursor: pointer;
  color:var(--C1);
  text-transform: uppercase;
}

.site-banniere-info-listitem>span{
  cursor: pointer;
}

.site-row {
  width: var(--WIDTH);
  margin-left: auto;
  margin-right: auto;
  margin-top: 40px;
  margin-bottom: 40px;
}

.site-row>h3 {
  text-align: center;
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  color:var(--C1);
  font-weight: bold;
}

@media screen and (max-width: 800px) {

  .site-banniere {
    grid-template-columns: 100%;
    grid-template-rows: 200px auto;
    gap:30px;
    height: unset;
  }

  .site-banniere-logo>img{
    height:200px;
  }

  .site-banniere-info{
    display:grid;
    width:100%;
    grid-template-rows: 40px 80px auto;
  }

}
</style>