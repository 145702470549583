import { createWebHashHistory, createRouter} from 'vue-router'
import home from "@/pages/page-home"
import error from "@/pages/page-error"
import mentions from "@/pages/page-mentions"

const routes = [
    {
        path:'/',
        component: home
    },
    {
        path:'/mentions',
        component: mentions
    },
    {
        path:"/:catchAll(.*)",
        component: error
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;